/* Order Edit, Vue Component */
<template>
  <div>
    <order-summary v-if="entity && entity.Id && !loading"
      :title="`Order - ${entity.Reference} - ${entity.MemberId_FullName}`"
      :order="entity">

        <div>
          <h4>Quote Information</h4>
          <output>{{ entity.QuoteRequest }}</output>
        </div>

      </order-summary>
    <v-card v-if="entity && !loading && !initialLoad" id="order-edit" class="entity-edit mt-6">
        <v-card-title>Send Quote</v-card-title>

        <v-card-text class="d-flex align-center flex-wrap pb-0">

          <p>Enter the details of the quote below. These will be <strong>emailed to the customer</strong>
            for them to accept.
          </p>

          <v-alert
            v-if="serverErrorMessage"
            type="error"
          >
            {{ serverErrorMessage }}
          </v-alert>
        </v-card-text>

        <v-form ref="form" class="mb-6 px-4">

          <v-row>
            <v-col cols="6">

              <EditablePriceList :items="QuoteBreakdownItems" @addItem="UpdatePrice" @removeItem="RemoveItem" />

              <h4 class="mb-1 mt-6">Quote Price</h4>

              <output class="text-h3 info--text">{{ Display.Money(quote.QuotePrice) }}</output>

              <!-- <v-text-field
              label="Quote Price"
              prefix="£"
              type="number"
              dense
              outlined
              flat
              :error-messages="serverErrors.filter(x => x.field === 'QuotePrice').map(x => x.error)"
              v-model="quote.QuotePrice"
              :rules="[validators.requiredNumber, validators.integerRange(quote.QuotePrice, 0, 10000)]"
            ></v-text-field> -->


            <h4 class="mb-1 mt-8">Information for customer</h4>


            <v-textarea
              label="Narrative"
              dense
              outlined
              flat
              multiline
              :error-messages="serverErrors.filter(x => x.field === 'QuoteComment').map(x => x.error)"
              v-model="quote.QuoteComment"
            ></v-textarea>

            </v-col>
            <v-col cols="6">
            <h4>Preset Prices</h4>

            <div class="mt-2 d-flex flex-wrap" style="gap: 10px" v-if="PalletPrice">
            <v-btn color="secondary" @click="UpdatePrice({Id: uuidv4(), Key: 'Pallet x1', Value: PalletPrice})">Pallet x1</v-btn>
            <v-btn color="secondary" @click="UpdatePrice({Id: uuidv4(), Key: 'Pallet x2', Value: 2 * PalletPrice})">Pallet x2</v-btn>
            <v-btn color="secondary" @click="UpdatePrice({Id: uuidv4(), Key: 'Pallet x3', Value: 3 * PalletPrice})">Pallet x3</v-btn>
            <v-btn color="secondary" @click="UpdatePrice({Id: uuidv4(), Key: 'Pallet x4', Value: 4 * PalletPrice})">Pallet x4</v-btn>
            <v-btn color="secondary" @click="UpdatePrice({Id: uuidv4(), Key: 'Pallet x5', Value: 5 * PalletPrice})">Pallet x5</v-btn>
            <v-btn color="secondary" @click="UpdatePrice({Id: uuidv4(), Key: 'Pallet x6', Value: 6 * PalletPrice})">Pallet x6</v-btn>
            </div>

            <h4 class="mt-6">Order Extras</h4>
            <div class="mt-2 d-flex flex-wrap" style="gap: 10px" v-if="OrderExtraItems && entity">
              <v-checkbox :input-value="IsHeavy" @change="ChangeOrderHeavy" label="Heavy"></v-checkbox>
              <template v-if="ExtraIds">
                <template v-for="(item, index) in OrderExtraItems">
                  <v-checkbox v-if="item.Enabled || item.Name.includes('Danger')" :key="index" :input-value="ExtraIds.includes(item.Id)" @change="ChangeOrderExtra(item, ...arguments)" :label="item.Name"></v-checkbox>
                </template>
              </template>
            </div>

            </v-col>
        </v-row>


        </v-form>

        <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-row class="align-center pb-5">
          <v-col>
          <v-btn
            color="primary"
            class="me-3"
            @click="Save"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiContentSaveOutline  }}
            </v-icon>
            <span>Save Quote</span>
          </v-btn>
          </v-col>
          <v-col class="justify-end d-flex">
            <Can I="update" on="Quotes" v-if="entity.StatusId_Name == 'Quote Pending'">
            <action-confirm-dialog @confirmed="DeclineQuote" openButtonText="Decline Quote" dialogTitle="Decline Quote">
                <template v-slot:information>
                  <p>This quote will be archived and the will be customer informed.</p>
                </template>
                <v-btn color="error" class="me-3">
                  <span>Decline Quote</span>
                </v-btn>
              </action-confirm-dialog>
            </Can>

          <v-btn
            color="secondary"
            class="me-3"
            @click="Back"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back</span>
          </v-btn>
          </v-col>
        </v-row>
        </v-card-text>



    </v-card>
  </div>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import * as FrameworkValidators from '@core/utils/validation'
import * as Validators from '@/datahelpers/validation'
import DateField from '@/views/helpers/DateField'
import OrderSummary from './components/OrderSummary'
import EditablePriceList from '@/views/helpers/EditablePriceList'
import { Can } from '@casl/vue'
import ActionConfirmDialog from '@/views/helpers/ActionConfirmDialog'


import {
  mdiContentSaveOutline,
  mdiChevronLeft
} from '@mdi/js'
import { ref, watch, computed, expose } from '@vue/composition-api'

export default {
  components: {
    Can,
    ActionConfirmDialog,
    OrderSummary,
    DateField,
    EditablePriceList
  },
  setup() {

    const { route, router  } = useRouter();

    const quote = ref({
      QuotePrice: null
    });
    const QuoteBreakdownItems = ref(null);

    const entity = computed(() => {
      if (!initialLoad.value && store.state.app.Order) {
        if (!quote.value.QuotePrice) quote.value.QuotePrice = store.state.app.Order.QuotePrice;
        if (!quote.value.QuoteComment) quote.value.QuoteComment = store.state.app.Order.QuoteNarrative;
        if (!QuoteBreakdownItems.value) QuoteBreakdownItems.value = store.state.app.Order.QuoteBreakdownItems;

        if (!ExtraIds.value) ExtraIds.value = store.state.app.Order.ExtrasIds;
        if (!IsHeavy.value) IsHeavy.value = store.state.app.Order.HeavyItem;

        return store.state.app.Order;
      }
      return null;
    });
    const loading = ref(true);
    const initialLoad = ref(true);
    const form = ref(null);
    const serverErrors = ref([]);
    const serverErrorMessage = ref(null);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchOrder', {
          id: id
        })
        .then(response => {
          loading.value = false;
          initialLoad.value = false;
        })
        .catch(error => {
          loading.value = false;
          initialLoad.value = false;
          console.log(error);
          router.push({ name: 'order-list', params: { id: route.value.params.id } });
        })
    }

    // fetch the user information when params change
    watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);

    const SafeSum2Dec = (a, b) => {
        return Math.round(((a + b) * 100)) / 100;
    };

    const uuidv4 = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    // update quote price based on items
    const UpdatePrice = (item) => {

      QuoteBreakdownItems.value.push(item);

      // QuoteBreakdownItems.value = items;
      quote.value.QuotePrice = QuoteBreakdownItems.value.reduce((prev, current) => {
        return SafeSum2Dec(prev, parseFloat(current.Value));
      }, 0);
    };

    const RemoveItem = (Id) => {
      QuoteBreakdownItems.value = QuoteBreakdownItems.value.filter(item => item.Id != Id);

      // QuoteBreakdownItems.value = items;
      quote.value.QuotePrice = QuoteBreakdownItems.value.reduce((prev, current) => {
        return SafeSum2Dec(prev, parseFloat(current.Value));
      }, 0);
    };


    const Save = function(e) {

      serverErrorMessage.value = null;
      serverErrors.value = [];

      const valid = form.value.validate();

      if (!valid) {
        return;
      }

      loading.value = true;

      store
        .dispatch('app/updateOrderQuote', {
          id: route.value.params.id,
          entity: entity.value,
          quote: Object.assign(quote.value, {
            QuoteBreakdownItems: QuoteBreakdownItems.value,
            ExtraIds: ExtraIds.value,
            IsHeavy: IsHeavy.value
          })
        })
        .then(response => {
          loading.value = false;
          router.push({ name: 'order-view', params: { id: response.data.Id }, hash: '#Billing' });
        })
        .catch((error) => {
          loading.value = false;
          if (error.error) {
            if (error.message) serverErrorMessage.value = error.message;
            if (error.errors) serverErrors.value = error.errors
          } else {
            console.log(error);
          }
        });
    };

    const Back = () => {
      router.go(-1);
    };

    const validators = {
      ...FrameworkValidators,
      ...Validators
    };

    const PalletPrice = ref(null);
    const fetchPalletPrice = () => {
      store
        .dispatch('app/fetchSettings', {
          filter: {
            Category: "Items"
          },
        })
        .then(response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            let palletPriceSetting = response.data.items.find(setting => setting.Name == "PalletPrice");
            if (palletPriceSetting) {
              PalletPrice.value = palletPriceSetting.Value;
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    fetchPalletPrice();


  const orderExtraOptions = {
    sortBy: ['SortOrder'],
    sortDesc: [false],
    itemsPerPage: 20
  };

  // items
  const OrderExtraItems = ref([]);

  // data for filter fields
  const orderExtraFilterItems = {
    // Enabled: true // need to get all so we can hard-code the DGN one that is no longer enabled
  };

  // method for data loading via vuex
  const fetchOrderExtraData = () => {
    store
      .dispatch('app/fetchOrderExtras', {
        search: {},
        options: orderExtraOptions,
        filter: orderExtraFilterItems,
      })
       .then(response => {
          if (response && response.data && response.data.items) {
            OrderExtraItems.value = response.data.items;
          }
       })
  };
  fetchOrderExtraData();

  const ExtraIds = ref(null);

  const ChangeOrderExtra = (item, value) => {

    // remove the id then add if this wes ticked
      // also add/remove price
    if (!ExtraIds.value) ExtraIds.value = [];

    ExtraIds.value = ExtraIds.value.filter(id => id != item.Id);
    RemoveItem("Extra-"+item.Id);

    // add back in
    if (value) {
      UpdatePrice({Id: "Extra-"+item.Id, Key: item.Name, Value: item.Price});
      ExtraIds.value.push(item.Id);
    }

  };

  const IsHeavy = ref(null);
  const ChangeOrderHeavy = (value) => {
    // RemoveItem("Heavy");
    IsHeavy.value = value;
    // if (value) {
    //   UpdatePrice({Id: "Heavy", Key: "Heavy", Value: 30});
    // }
  };

  const DeclineQuote = (comment) => {
    store
        .dispatch('app/declineQuote', {
          entity: entity.value,
          comment: comment
        })
        .then(response => {
          loading.value = false;
          router.push({ name: 'order-view', params: { id: entity.value.Id } });
        })
        .catch(error => {
          console.log(error);
          //router.push({ name: 'order-list'});
        })
  }

    return {
      DeclineQuote,
      ChangeOrderHeavy,
      ExtraIds,
      IsHeavy,
      ChangeOrderExtra,
      OrderExtraItems,
      PalletPrice,
      uuidv4,
      RemoveItem,
      QuoteBreakdownItems,
      UpdatePrice,
      quote,
      Back,
      form,
      Save,
      loading,
      entity,
      Display,
      icons: {
        mdiContentSaveOutline ,
        mdiChevronLeft
      },
      validators,
      serverErrorMessage,
      serverErrors
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-edit::v-deep .v-text-field:first-child {
  max-width: 220px;
}

.entity-edit::v-deep .v-text-field:nth-child(2) {
  max-width: 420px;
}
</style>

